// https://nuxtjs.org/docs/2.x/directory-structure/store
// Your state value should always be a function to avoid unwanted shared state on the server side.
// To get started, export the state as a function, and the mutations and actions as objects.
import shopifyAppBridge from '@shopify/app-bridge'
import { getSessionToken } from '@shopify/app-bridge-utils'
import { Redirect } from '@shopify/app-bridge/actions'
import { handleStoreErrors } from '@/utils/survey'

export const state = () => ({
  errors: [],
  sessionToken: '',
  shopifyApp: null,
  shopOrigin: '',
  shopifyRedirect: null,
})

export const mutations = {
  RESET_ERRORS(state) {
    state.errors = []
  },
  SET_ERRORS(state, errors) {
    state.errors = errors
  },
  SET_SESSION_TOKEN(state, payload) {
    state.sessionToken = payload
  },
  SET_SHOPIFY_APP(state, payload) {
    state.shopifyApp = payload
  },
  SET_SHOP_ORIGIN(state, payload) {
    state.shopOrigin = payload
  },
  SET_SHOPIFY_REDIRECT(state, payload) {
    state.shopifyRedirect = payload
  },
}

export const getters = {
  sessionToken(state) {
    return state.sessionToken
  },
  shopifyApp(state) {
    return state.shopifyApp
  },
}

export const actions = {
  adminRedirect({ state }, path) {
    state.shopifyRedirect.dispatch(Redirect.Action.ADMIN_PATH, path)
  },
  async getShopifySessionToken({ commit, dispatch, state }) {
    commit('RESET_ERRORS')

    try {
      // Get the session token
      const sessionToken = await getSessionToken(state.shopifyApp)

      if (!sessionToken) {
        throw new Error('Could not get sessionToken.')
      }

      // Save it to the store
      commit('SET_SESSION_TOKEN', sessionToken)
    } catch (error) {
      this.$sentry.captureException(error)
      dispatch('handleErrors', error)
    }
  },
  handleErrors({ commit }, errors) {
    handleStoreErrors(errors, commit)
  },
  saveShopOrigin({ commit }, payload) {
    commit('SET_SHOP_ORIGIN', payload)
  },
  setupAppBridge({ commit, dispatch, state }, payload) {
    commit('RESET_ERRORS')

    const createShopifyApp = shopifyAppBridge
    const apiKey = process.env.SHOPIFY_API_KEY
    const shopOrigin = payload.shopOrigin || state.shopOrigin
    const { host } = payload

    // Save the host to local storage so we can reinstatiate app bridge if need be
    if (host && typeof window !== 'undefined' && process?.env?.isLocal)
      window?.localStorage?.setItem('pps-host', host)

    try {
      const shopifyApp = createShopifyApp({
        apiKey,
        shopOrigin,
        host,
      })

      if (!shopifyApp) {
        throw new Error('No shopifyApp created.')
      }

      commit('SET_SHOPIFY_APP', shopifyApp)
      dispatch('setupShopifyRedirect', shopifyApp)
    } catch (error) {
      this.$sentry.captureException(error)
      dispatch('handleErrors', error)
    }
  },
  setupShopifyRedirect({ commit }, shopifyApp) {
    const shopifyRedirect = Redirect.create(shopifyApp)
    commit('SET_SHOPIFY_REDIRECT', shopifyRedirect)
  },
}
