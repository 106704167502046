export const BannersAppNotInUse = () => import('../../components/banners/AppNotInUse.vue' /* webpackChunkName: "components/banners-app-not-in-use" */).then(c => wrapFunctional(c.default || c))
export const BannersUpsell = () => import('../../components/banners/BannersUpsell.vue' /* webpackChunkName: "components/banners-upsell" */).then(c => wrapFunctional(c.default || c))
export const Banners = () => import('../../components/banners/Index.vue' /* webpackChunkName: "components/banners" */).then(c => wrapFunctional(c.default || c))
export const BannersRequestReview = () => import('../../components/banners/RequestReview.vue' /* webpackChunkName: "components/banners-request-review" */).then(c => wrapFunctional(c.default || c))
export const SurveyExample = () => import('../../components/survey/SurveyExample.vue' /* webpackChunkName: "components/survey-example" */).then(c => wrapFunctional(c.default || c))
export const SurveyQuestionEdit = () => import('../../components/survey/QuestionEdit/QuestionEdit.vue' /* webpackChunkName: "components/survey-question-edit" */).then(c => wrapFunctional(c.default || c))
export const SurveyQuestionEditRadioCheckboxInput = () => import('../../components/survey/QuestionEdit/RadioCheckboxInput.vue' /* webpackChunkName: "components/survey-question-edit-radio-checkbox-input" */).then(c => wrapFunctional(c.default || c))
export const SurveyQuestionStats = () => import('../../components/survey/QuestionStats/QuestionStats.vue' /* webpackChunkName: "components/survey-question-stats" */).then(c => wrapFunctional(c.default || c))
export const SurveyQuestionsList = () => import('../../components/survey/QuestionsList/QuestionsList.vue' /* webpackChunkName: "components/survey-questions-list" */).then(c => wrapFunctional(c.default || c))
export const SurveyNav = () => import('../../components/survey/SurveyNav/SurveyNav.vue' /* webpackChunkName: "components/survey-nav" */).then(c => wrapFunctional(c.default || c))
export const SurveyStats = () => import('../../components/survey/SurveyStats/SurveyStats.vue' /* webpackChunkName: "components/survey-stats" */).then(c => wrapFunctional(c.default || c))
export const SurveySubmissionItem = () => import('../../components/survey/SurveySubmissions/SurveySubmissionItem.vue' /* webpackChunkName: "components/survey-submission-item" */).then(c => wrapFunctional(c.default || c))
export const SurveySubmissions = () => import('../../components/survey/SurveySubmissions/SurveySubmissions.vue' /* webpackChunkName: "components/survey-submissions" */).then(c => wrapFunctional(c.default || c))
export const SurveySubmissionsEmpty = () => import('../../components/survey/SurveySubmissions/SurveySubmissionsEmpty.vue' /* webpackChunkName: "components/survey-submissions-empty" */).then(c => wrapFunctional(c.default || c))
export const SurveyTemplates = () => import('../../components/survey/SurveyTemplates/SurveyTemplates.vue' /* webpackChunkName: "components/survey-templates" */).then(c => wrapFunctional(c.default || c))
export const UiAlert = () => import('../../components/ui/UiAlert/UiAlert.vue' /* webpackChunkName: "components/ui-alert" */).then(c => wrapFunctional(c.default || c))
export const UiBadge = () => import('../../components/ui/UiBadge/UiBadge.vue' /* webpackChunkName: "components/ui-badge" */).then(c => wrapFunctional(c.default || c))
export const UiBanner = () => import('../../components/ui/UiBanner/UiBanner.vue' /* webpackChunkName: "components/ui-banner" */).then(c => wrapFunctional(c.default || c))
export const UiButton = () => import('../../components/ui/UiButton/UiButton.vue' /* webpackChunkName: "components/ui-button" */).then(c => wrapFunctional(c.default || c))
export const UiCard = () => import('../../components/ui/UiCard/UiCard.vue' /* webpackChunkName: "components/ui-card" */).then(c => wrapFunctional(c.default || c))
export const UiCheckbox = () => import('../../components/ui/UiCheckbox/UiCheckbox.vue' /* webpackChunkName: "components/ui-checkbox" */).then(c => wrapFunctional(c.default || c))
export const UiHeader = () => import('../../components/ui/UiHeader/UiHeader.vue' /* webpackChunkName: "components/ui-header" */).then(c => wrapFunctional(c.default || c))
export const UiInputError = () => import('../../components/ui/UiInputError/UiInputError.vue' /* webpackChunkName: "components/ui-input-error" */).then(c => wrapFunctional(c.default || c))
export const UiLoader = () => import('../../components/ui/UiLoader/UiLoader.vue' /* webpackChunkName: "components/ui-loader" */).then(c => wrapFunctional(c.default || c))
export const UiMedia = () => import('../../components/ui/UiMedia/UiMedia.vue' /* webpackChunkName: "components/ui-media" */).then(c => wrapFunctional(c.default || c))
export const UiRadioInput = () => import('../../components/ui/UiRadioInput/UiRadioInput.vue' /* webpackChunkName: "components/ui-radio-input" */).then(c => wrapFunctional(c.default || c))
export const UiSelectButton = () => import('../../components/ui/UiSelectButton/UiSelectButton.vue' /* webpackChunkName: "components/ui-select-button" */).then(c => wrapFunctional(c.default || c))
export const UiSelectInput = () => import('../../components/ui/UiSelectInput/UiSelectInput.vue' /* webpackChunkName: "components/ui-select-input" */).then(c => wrapFunctional(c.default || c))
export const UiSlider = () => import('../../components/ui/UiSlider/UiSlider.vue' /* webpackChunkName: "components/ui-slider" */).then(c => wrapFunctional(c.default || c))
export const UiTextInput = () => import('../../components/ui/UiTextInput/UiTextInput.vue' /* webpackChunkName: "components/ui-text-input" */).then(c => wrapFunctional(c.default || c))
export const UiTextarea = () => import('../../components/ui/UiTextarea/UiTextarea.vue' /* webpackChunkName: "components/ui-textarea" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
