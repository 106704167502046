import Vue from 'vue'
import { format } from 'date-fns'
import formatDistance from 'date-fns/formatDistance'

// Filters
Vue.filter('formatDateDistance', (value) => {
  if (!value) return ''

  return formatDistance(new Date(value), new Date(), {
    addSuffix: true,
  })
})

Vue.filter('formatDate', (value) => {
  if (!value) return ''

  return format(new Date(value), 'do MMMM yyyy')
})
