import { ApolloLink } from 'apollo-link'
import loggerLink from '@/lib/apollo/links/logger-link'
import authLink from '@/lib/apollo/links/auth-link'
import errorLink from '@/lib/apollo/links/error-link'
import retryLink from '@/lib/apollo/links/retry-link'
import shopifyAppBridgeLink from '@/lib/apollo/links/shopify-app-bridge-link'

export default ({ store, route, $sentry }) => {
  // Setup our links...
  // Taken from https://medium.com/@joanvila/productionizing-apollo-links-4cdc11d278eb
  const link = ApolloLink.from([
    loggerLink,
    errorLink,
    shopifyAppBridgeLink(store, route, $sentry),
    authLink(store),
    retryLink,
  ])

  return {
    httpEndpoint: `${process.env.SERVICES_URL}/graphql`,
    link,
  }
}
