import { RetryLink } from 'apollo-link-retry'

const retryIf = (error, operation) => {
  // Otherwise do not retry if there's an error and it includes one of the following status codes...
  const doNotRetryCodes = [500]
  // const doNotRetryCodes = [500, 400, 401];
  return !!error && !doNotRetryCodes.includes(error.statusCode)
}

export default new RetryLink({
  delay: {
    initial: 100,
    max: 2000,
    jitter: true,
  },
  attempts: {
    max: 3,
    retryIf,
  },
})
