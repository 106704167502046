/* eslint-disable no-console */
import { ApolloLink } from 'apollo-link'

// operation: Provides information and a context to the GraphQL operation being performed.
// forward: A method to be called in order to continue the chain of links.
export default new ApolloLink((operation, forward) => {
  if (!process.env.isProduction) {
    // Get the initial time...
    operation.setContext({ start: new Date() })

    // Perform the http request with forward and map the response...
    return forward(operation).map((response) => {
      // Subtract the initial time from the current one to calculate the response time...
      const responseTimeMs = new Date() - operation.getContext().start
      const responseTimeSeconds = responseTimeMs / 1000

      // Log that the http request has finished successfully...
      console.groupCollapsed(
        `✅GraphQL: ${operation.operationName} (${responseTimeSeconds}s)`
      )
      console.log(response)
      console.groupEnd()

      return response
    })
  }

  return forward(operation)
})
