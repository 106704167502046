import Vue from 'vue'
import { nanoid } from 'nanoid'
import isFunction from 'lodash/isFunction'
import isArray from 'lodash/isArray'

const addRandomIdsToOptions = (options) => {
  if (!options || !options.length) return options

  return options.map((o) => {
    const option = { ...o }

    // Remove the __typename prop as it gets added when we clone the object...
    delete option.__typename

    // Add a unique id...
    if (!option.optionId) option.optionId = nanoid()

    return option
  })
}

export const prepareSurveyQuestions = (s) => {
  // If there are no questions then return the original survey...
  if (!s?.questions?.length) return s

  // Create a copy of the survey...
  const survey = { ...s }

  // Loop through the questions...
  survey.questions = survey?.questions.map((q) => {
    // Create a copy of the question...
    const question = { ...q }

    // Remove the __typename prop as it gets added when we clone the object...
    delete question.__typename

    // Add a unique id...
    if (!question.questionId) question.questionId = nanoid()

    // If there are no options then return the question...
    if (!question?.options?.length) return question

    // Loop through the options and add random IDs...
    question.options = addRandomIdsToOptions(question.options)

    return question
  })

  return survey
}

export const handleStoreErrors = (errors, commit, mutation = 'SET_ERRORS') => {
  // wrap the error in an array if it's just a single error
  const e = isArray(errors) ? errors.map((error) => error.message) : [errors]

  Vue.$log?.error(errors)

  if (isFunction(commit)) commit(mutation, e)
}
