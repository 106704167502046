import Vue from 'vue'
import VueLogger from 'vuejs-logger'
import { IS_PRODUCTION } from '@/utils/constants'

const options = {
  isEnabled: true,
  logLevel: IS_PRODUCTION ? 'error' : 'debug',
  showMethodName: true,
  showConsoleColors: true,
}

Vue.use(VueLogger, options)
