import { setContext } from 'apollo-link-context'

const getSessionToken = async (store) => {
  // Update the session token in our store...
  await store.dispatch('shopify/getShopifySessionToken')

  // Get the latest session token from the store...
  return store.getters['shopify/sessionToken']
}

export default (store, route, $sentry) =>
  setContext(async () => {
    // Get the shopifyApp from the Shopify store...
    const shopifyApp = store.getters['shopify/shopifyApp']

    if (!shopifyApp && process.client) {
      console.warn('No Shopify App found')

      const host = window?.localStorage?.getItem('pps-host')

      if (!host) throw new Error('No host found')

      // Try to reinstantiate the app bridge client...
      try {
        const sessionToken = await store
          .dispatch('shopify/setupAppBridge', { host })
          .then(async () => {
            // Once it's setup get a new session token...
            return await getSessionToken(store)
          })

        return { sessionToken }
      } catch (error) {
        $sentry.captureException(error)
        // Return an empty sessionToken if we can't get a new one...
        return { sessionToken: '' }
      }
    }

    // Get a new sessionToken...
    const sessionToken = await getSessionToken(store)

    if (!process.env.isProduction) {
      console.groupCollapsed('ℹ️SessionToken')
      console.info(sessionToken)
      console.groupEnd()
    }

    return { sessionToken }
  })
