import { gql } from 'graphql-tag'

export const SurveyQuery = gql`
  query Survey {
    survey {
      surveyId
      dateCreated
      surveyStats {
        viewCount
        submissionCount
        completionRate
      }
      questions {
        questionId
        type
        label
        options {
          label
          value
        }
      }
    }
  }
`

export const QuestionStatsQuery = gql`
  query QuestionStats($input: QuestionStatsInput!) {
    questionStats(input: $input) {
      label
      percentage
    }
  }
`

export const SubmissionsQuery = gql`
  query Submissions($input: SubmissionsInput!) {
    submissions(input: $input) {
      submissions {
        submission {
          label
          value
        }
        submittedBy
        submittedDate
        initialPurchase {
          totalCost
          products {
            title
            productUrl
            imageUrl
            quantity
            cost
          }
        }
      }
      paginationKey
    }
  }
`

export const PrefilledTemplatesQuery = gql`
  query PrefilledTemplates {
    prefilledTemplates {
      label
      description
      imageUrl
      template {
        surveyId
        questions {
          label
          type
          questionId
          options {
            label
            value
          }
        }
      }
    }
  }
`

export const AccountQuery = gql`
  query Account {
    account {
      isPostPurchaseAppInUse
    }
  }
`
