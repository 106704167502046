// https://nuxtjs.org/docs/2.x/directory-structure/store
// Your state value should always be a function to avoid unwanted shared state on the server side.
// To get started, export the state as a function, and the mutations and actions as objects.
import { AccountQuery } from '@/graphql/queries'
import { handleStoreErrors } from '@/utils/survey'

export const state = () => ({
  account: null,
  errors: [],
  loadingAccount: false,
})

export const mutations = {
  RESET_ERRORS(state) {
    state.errors = []
  },
  SET_ACCOUNT(state, account) {
    state.account = account
  },
  SET_ERRORS(state, errors) {
    state.errors = errors
  },
  SET_LOADING_ACCOUNT(state, loadingAccount) {
    state.loadingAccount = loadingAccount
  },
}

export const getters = {
  account(state) {
    return state.account
  },
}

export const actions = {
  async getAccount({ commit, dispatch }) {
    commit('RESET_ERRORS')
    commit('SET_LOADING_ACCOUNT', true)

    const client = this.app.apolloProvider.defaultClient

    const options = {
      fetchPolicy: 'network-only',
      query: AccountQuery,
    }

    try {
      const { errors, data } = await client.query(options)

      commit('SET_ACCOUNT', data?.account)
      commit('SET_LOADING_ACCOUNT', false)

      if (errors) {
        return dispatch('handleErrors', errors)
      }
    } catch (error) {
      this.$sentry.captureException(error)
      dispatch('handleErrors', error)
      commit('SET_LOADING_ACCOUNT', false)
    }
  },
  handleErrors({ commit }, errors) {
    handleStoreErrors(errors, commit)
  },
}
