// https://nuxtjs.org/docs/2.x/directory-structure/store
// Your state value should always be a function to avoid unwanted shared state on the server side.
// To get started, export the state as a function, and the mutations and actions as objects.

import { nanoid } from 'nanoid'
import { PrefilledTemplatesQuery, SurveyQuery } from '@/graphql/queries'
import {
  CreateSurvey,
  DeleteSurvey,
  GenerateReport,
  ResetSurveyData,
  UpdateSurvey,
} from '@/graphql/mutations'
import { handleStoreErrors, prepareSurveyQuestions } from '@/utils/survey'
import { IS_DEV } from '@/utils/constants'

export const state = () => ({
  deletingSurvey: false,
  errors: [],
  exportingSurvey: false,
  loadingSurvey: false,
  loadingTemplates: false,
  resetingSurveyData: false,
  savingSurvey: false,
  survey: null,
  templates: [],
})

const backupSurvey = {
  surveyId: null,
  questions: [
    {
      questionId: null,
      label: '',
      type: 'radio',
      options: [
        {
          label: '',
          value: '',
        },
        {
          label: '',
          value: '',
        },
        {
          label: '',
          value: '',
        },
      ],
    },
  ],
}

export const mutations = {
  ADD_QUESTION_OPTION(state, questionId) {
    // Get the question's index...
    const questionIndex = state?.survey?.questions.findIndex(
      (q) => q.questionId === questionId
    )

    if (questionIndex !== -1) {
      // Get the current question...
      const question = state?.survey?.questions[questionIndex]
      // Add the new option to the question...
      question.options.push({
        optionId: nanoid(),
        label: '',
        value: '',
      })
      // Update the question...
      state.survey.questions[questionIndex] = question
    }
  },
  REMOVE_QUESTION_OPTION(state, { questionId, optionId }) {
    // Get the question's index...
    const questionIndex = state?.survey?.questions.findIndex(
      (q) => q.questionId === questionId
    )

    if (questionIndex !== -1) {
      // Get the current question...
      const question = state?.survey?.questions[questionIndex]
      // Remove the option from the question's options...
      if (question?.options.length) {
        // Filter the options and return the ones where the option id is not equal to optionId...
        question.options = question.options.filter(
          (option) => option.optionId !== optionId
        )
      }

      // Update the question...
      state.survey.questions[questionIndex] = question
    }
  },
  RESET_ERRORS(state) {
    state.errors = []
  },
  SET_DELETING_SURVEY(state, deletingSurvey) {
    state.deletingSurvey = deletingSurvey
  },
  SET_ERRORS(state, errors) {
    state.errors = errors
  },
  SET_EXPORTING_SURVEY(state, exportingSurvey) {
    state.exportingSurvey = exportingSurvey
  },
  SET_LOADING_SURVEY(state, loadingSurvey) {
    state.loadingSurvey = loadingSurvey
  },
  SET_LOADING_TEMPLATES(state, loadingTemplates) {
    state.loadingTemplates = loadingTemplates
  },
  SET_RESETING_SURVEY_DATA(state, resetingSurveyData) {
    state.resetingSurveyData = resetingSurveyData
  },
  SET_SAVING_SURVEY(state, savingSurvey) {
    state.savingSurvey = savingSurvey
  },
  SET_BACKUP_SURVEY(state, survey) {
    state.survey = survey
  },
  SET_SURVEY(state, survey) {
    state.survey = survey
  },
  SET_TEMPLATES(state, templates) {
    state.templates = templates
  },
  UPDATE_QUESTION_LABEL(state, { questionId, label }) {
    // Get the question's index...
    const questionIndex = state?.survey?.questions.findIndex(
      (q) => q.questionId === questionId
    )

    if (questionIndex !== -1) {
      // Get the current question...
      const question = state?.survey?.questions[questionIndex]

      // Update the question label
      question.label = label

      // Update the question...
      state.survey.questions[questionIndex] = question
    }
  },
  UPDATE_QUESTION_OPTION(state, { questionId, label, optionId }) {
    // Get the question's index...
    const questionIndex = state?.survey?.questions.findIndex(
      (q) => q.questionId === questionId
    )

    if (questionIndex !== -1) {
      // Get the current question...
      const question = state?.survey?.questions[questionIndex]
      if (question?.options.length) {
        // Update the question's option...
        question.options = question.options.map((o) => {
          const option = { ...o }
          if (o.optionId === optionId) {
            option.label = label
          }
          return option
        })
      }

      // Update the question...
      state.survey.questions[questionIndex] = question
    }
  },
  UPDATE_QUESTION_TYPE(state, { questionId, type }) {
    // Get the question's index...
    const questionIndex = state?.survey?.questions.findIndex(
      (q) => q.questionId === questionId
    )

    if (questionIndex !== -1) {
      // Get the current question...
      const question = state?.survey?.questions[questionIndex]

      // Update the question label
      question.type = type

      // Reset the question options
      question.options = []

      // Update the question...
      state.survey.questions[questionIndex] = question
    }
  },
}

export const getters = {
  survey(state) {
    return state.survey
  },
}

export const actions = {
  async deleteSurvey({ commit, dispatch }, surveyId) {
    commit('RESET_ERRORS')
    commit('SET_DELETING_SURVEY', true)

    const client = this.app.apolloProvider.defaultClient
    const options = {
      mutation: DeleteSurvey,
      variables: {
        input: {
          surveyId,
        },
      },
    }

    try {
      const { errors, data } = await client.mutate(options)
      // Reset the survey store state...
      if (data?.deleteSurvey) commit('SET_SURVEY', null)

      commit('SET_DELETING_SURVEY', false)

      if (errors) {
        return dispatch('handleErrors', errors)
      }
    } catch (error) {
      this.$sentry.captureException(error)
      if (process.client) alert('Could not delete survey. Please try again.')
      dispatch('handleErrors', error)
      commit('SET_DELETING_SURVEY', false)
    }
  },
  async exportSurvey({ commit, dispatch }, surveyId) {
    commit('RESET_ERRORS')
    commit('SET_EXPORTING_SURVEY', true)

    const client = this.app.apolloProvider.defaultClient
    const options = {
      mutation: GenerateReport,
      variables: {
        input: {
          surveyId,
        },
      },
    }

    try {
      const { errors, data } = await client.mutate(options)
      const reportUrl = data?.generateReport?.url

      if (reportUrl) {
        window.document.location.href = reportUrl
      } else {
        throw new Error('No report url returned from server')
      }

      commit('SET_EXPORTING_SURVEY', false)

      if (errors) {
        return dispatch('handleErrors', errors)
      }
    } catch (error) {
      this.$sentry.captureException(error)
      dispatch('handleErrors', error)
      commit('SET_EXPORTING_SURVEY', false)
    }
  },
  handleErrors({ commit }, errors) {
    handleStoreErrors(errors, commit)
  },
  async resetSurveyData({ commit, dispatch }, surveyId) {
    commit('RESET_ERRORS')
    commit('SET_RESETING_SURVEY_DATA', true)

    const client = this.app.apolloProvider.defaultClient
    const options = {
      mutation: ResetSurveyData,
      variables: {
        input: {
          surveyId,
        },
      },
    }

    try {
      const { errors, data } = await client.mutate(options)
      const survey = prepareSurveyQuestions(data?.resetSurveyData)

      commit('SET_RESETING_SURVEY_DATA', false)

      if (errors) {
        return dispatch('handleErrors', errors)
      }

      commit('SET_SURVEY', survey)
    } catch (error) {
      this.$sentry.captureException(error)
      dispatch('handleErrors', error)
      if (process.client)
        alert('Could not reset survey data. Please try again.')
      commit('SET_RESETING_SURVEY_DATA', false)
    }
  },
  async saveSurvey({ commit, dispatch, state }, surveyId) {
    commit('RESET_ERRORS')
    commit('SET_SAVING_SURVEY', true)

    const client = this.app.apolloProvider.defaultClient
    const options = {
      mutation: surveyId ? UpdateSurvey : CreateSurvey,
      variables: {
        input: {
          questions: state?.survey?.questions,
        },
      },
    }

    if (surveyId) options.variables.input.surveyId = surveyId

    try {
      const { errors, data } = await client.mutate(options)
      const survey = surveyId ? data?.updateSurvey : data?.createSurvey

      commit('SET_SAVING_SURVEY', false)

      if (errors) {
        return dispatch('handleErrors', errors)
      }

      commit('SET_SURVEY', survey)
    } catch (error) {
      this.$sentry.captureException(error)
      if (process.client) alert('Could not save survey. Please try again.')
      dispatch('handleErrors', error)
      commit('SET_SAVING_SURVEY', false)
    }
  },
  async getSurvey({ commit, dispatch }, surveyId) {
    commit('SET_LOADING_SURVEY', true)
    const client = this.app.apolloProvider.defaultClient

    const options = {
      fetchPolicy: 'network-only',
      query: SurveyQuery,
    }

    if (surveyId) options.variables = { surveyId }

    try {
      const { errors, data } = await client.query(options)

      const survey = prepareSurveyQuestions(data?.survey)

      commit('SET_LOADING_SURVEY', false)

      if (errors) {
        return dispatch('handleErrors', errors)
      }

      commit('SET_SURVEY', survey)

      if (IS_DEV) console.log('surveyId', survey.surveyId)
    } catch (error) {
      this.$sentry.captureException(error)
      if (process.client) alert('Could not get survey. Please try again.')
      dispatch('handleErrors', error)
      commit('SET_LOADING_SURVEY', false)
    }
  },
  async getPrefilledTemplates({ commit, dispatch }) {
    commit('SET_LOADING_TEMPLATES', true)
    const client = this.app.apolloProvider.defaultClient

    const options = {
      fetchPolicy: 'cache-first',
      query: PrefilledTemplatesQuery,
    }

    try {
      const { errors, data } = await client.query(options)

      commit('SET_LOADING_TEMPLATES', false)

      if (errors) {
        return dispatch('handleErrors', errors)
      }

      commit('SET_TEMPLATES', data?.prefilledTemplates)
    } catch (error) {
      this.$sentry.captureException(error)
      if (process.client) console.warn('Could not load templates.')
      commit('SET_LOADING_TEMPLATES', false)
      commit('SET_BACKUP_SURVEY', prepareSurveyQuestions(backupSurvey))
    }
  },
  usePrefilledTemplate({ commit }, templateSurvey) {
    // Add random ids to any question options...
    const survey = prepareSurveyQuestions(templateSurvey)

    commit('SET_SURVEY', survey)
  },
  addQuestionOption({ commit }, questionId) {
    commit('ADD_QUESTION_OPTION', questionId)
  },
  updateQuestionLabel({ commit }, payload) {
    commit('UPDATE_QUESTION_LABEL', payload)
  },
  updateQuestionOption({ commit }, payload) {
    commit('UPDATE_QUESTION_OPTION', payload)
  },
  updateQuestionType({ commit }, payload) {
    commit('UPDATE_QUESTION_TYPE', payload)
  },
  removeQuestionOption({ commit }, payload) {
    commit('REMOVE_QUESTION_OPTION', payload)
  },
  resetSurveyState({ commit }) {
    commit('SET_SURVEY', null)
  },
}
