import { gql } from 'graphql-tag'

export const CreateSurvey = gql`
  mutation CreateSurvey($input: CreateSurveyInput!) {
    createSurvey(input: $input) {
      surveyId
      dateCreated
      surveyStats {
        viewCount
        submissionCount
        completionRate
      }
      questions {
        questionId
        type
        label
        options {
          label
          value
        }
      }
    }
  }
`

export const UpdateSurvey = gql`
  mutation UpdateSurvey($input: UpdateSurveyInput!) {
    updateSurvey(input: $input) {
      surveyId
      dateCreated
      surveyStats {
        viewCount
        submissionCount
        completionRate
      }
      questions {
        questionId
        type
        label
        options {
          label
          value
        }
      }
    }
  }
`

export const DeleteSurvey = gql`
  mutation DeleteSurvey($input: DeleteSurveyInput!) {
    deleteSurvey(input: $input)
  }
`

export const ResetSurveyData = gql`
  mutation ResetSurveyData($input: ResetSurveyDataInput!) {
    resetSurveyData(input: $input) {
      surveyId
      dateCreated
      surveyStats {
        viewCount
        submissionCount
        completionRate
      }
      questions {
        questionId
        type
        label
        options {
          label
          value
        }
      }
    }
  }
`

export const GenerateReport = gql`
  mutation GenerateReport($input: GenerateReportInput!) {
    generateReport(input: $input) {
      url
    }
  }
`
