// import { setContext } from 'apollo-link-context'
import { ApolloLink } from '@apollo/client'

export default (store) =>
  new ApolloLink((operation, forward) => {
    const { headers, sessionToken } = operation.getContext()

    // Check AppBridge is instantiated...
    if (!sessionToken) {
      console.warn('No Shopify Session Token found. Aborting request.')

      return false
    }

    // Set the headers in the context...
    operation.setContext({
      headers: {
        ...headers,
        authorization: `Bearer ${sessionToken}` || '',
      },
    })

    return forward(operation)
  })
